const localStore = {
  set: ({ key, value }) => {
    localStorage.setItem(key, value)
  },
  get: key => {
    return localStorage.getItem(key)
  },
  remove: key => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
  clearExcept: (keysToKeep) => {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (!keysToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }
}

export default localStore